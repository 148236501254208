import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { BackendResponse } from '@fc-core/models/response.model';
import { MomentService } from '@fc-core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  nextUrl: string;
  historyEventsSbj = new BehaviorSubject([] as EventHistoryItem[]);
  loadingSbj = new BehaviorSubject(false);
  ladingMoreSbj = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private momentService: MomentService,
  ) {}

  get eventsHistory$(): Observable<
    {
      dateGroup: string;
      events: EventHistoryItem[];
    }[]
  > {
    return this.historyEventsSbj
      .asObservable()
      .pipe(map((events) => this.groupEventsByDate(events)));
  }

  get loading$(): Observable<boolean> {
    return this.loadingSbj.asObservable();
  }

  get loadingMore$(): Observable<boolean> {
    return this.ladingMoreSbj.asObservable();
  }

  loadEventHistoryItems(): void {
    if (!this.nextUrl && this.historyEventsSbj.getValue().length > 0) {
      return;
    }
    if (this.historyEventsSbj.getValue().length > 0) {
      this.ladingMoreSbj.next(true);
    } else {
      this.loadingSbj.next(true);
    }
    const url = this.nextUrl
      ? this.nextUrl
      : environment.apiUrl + 'api/notifications/?page_size=20';
    this.getEventsHistoryRequest(url)
      .pipe(
        tap(() => {
          if (this.historyEventsSbj.getValue().length > 0) {
            this.ladingMoreSbj.next(false);
          } else {
            this.loadingSbj.next(false);
          }
        }),
        take(1),
      )
      .subscribe((events) => {
        this.historyEventsSbj.next([
          ...this.historyEventsSbj.getValue(),
          ...events,
        ]);
      });
  }

  getEventsHistoryRequest(url: string): Observable<EventHistoryItem[]> {
    return this.http.get<BackendResponse<EventHistoryItem[]>>(url).pipe(
      map((resp) => {
        this.nextUrl = resp.next;
        return resp.results;
      }),
    );
  }

  private groupEventsByDate(events: EventHistoryItem[]): {
    dateGroup: string;
    events: EventHistoryItem[];
  }[] {
    const dateGroups = events.reduce((acc, event) => {
      const date = this.momentService.moment(event.created),
        isDateToday = date.isSame(this.momentService.moment(), 'day'),
        format = isDateToday ? 'DD MMM' : 'dddd, DD MMM',
        dateGroup = isDateToday
          ? 'Today, ' + date.format(format)
          : date.format(format);
      if (!acc[dateGroup]) {
        acc[dateGroup] = [];
      }
      acc[dateGroup].push(event);
      return acc;
    }, {});
    return Object.keys(dateGroups).map((dateGroup) => ({
      dateGroup,
      events: dateGroups[dateGroup] as EventHistoryItem[],
    }));
  }
}
