<div class="events-tab">
  @if (notificationService.loading$ | async) {
    <div class="events-list">
      <fc-skeleton-list></fc-skeleton-list>
    </div>
  } @else {
    <div
      fcScrollNearEnd
      (nearEnd)="notificationService.loadEventHistoryItems()"
      class="events-list"
    >
      @for (item of groupedEvents$ | async; track item.dateGroup) {
        <div class="event-item-group">
          <div class="event-item-group-title color-label">
            {{ item.dateGroup }}
          </div>
          @for (event of item.events; track event.id) {
            @if (event.context.LocationCondition) {
              <fc-location-event-item [event]="event"></fc-location-event-item>
            }
            @if (event.context.VehicleSpeedCondition) {
              <fc-vehicle-speed-event-item
                [event]="event"
              ></fc-vehicle-speed-event-item>
            }
            @if (event.context.MovementTypeCondition) {
              <fc-vehicle-state-event-item
                [event]="event"
              ></fc-vehicle-state-event-item>
            }
          }
        </div>
      } @empty {
        <div class="empty-state">
          <fc-empty-state
            [size]="72"
            svgIcon="icon-empty_state_history"
            subtitle="You have no notifications yet"
          ></fc-empty-state>
        </div>
      }
    </div>
  }

  @if (notificationService.loadingMore$ | async) {
    <div class="events-list-load-more">
      <mat-spinner class="spinner" diameter="30" strokeWidth="4"></mat-spinner>
    </div>
  }
</div>
