import { Component, effect, inject, input, Input } from '@angular/core';
import { MomentService } from '@fc-core';
import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { corePlatformColors } from '@fc-core/constants/colors';

@Component({
  selector: 'fc-vehicle-speed-event-item',
  template: `
    <div
      class="event-item"
      matRipple
      [routerLink]="
        'locations/main/location-list/' + event().context.vehicle.id
      "
    >
      <div class="image-container">
        <img [src]="event().context.vehicle.photo" alt="event-image" />
        <div
          class="status-icon"
          [style.background-color]="corePlatformColors.error100"
        >
          <fc-icon icon="icon-speed" color="error500" [size]="16"></fc-icon>
        </div>
      </div>

      <div class="event-info" *ngIf="event().context.vehicle">
        <div class="event-title h3">
          {{ event().context.vehicle.identifier }}
        </div>
        <div class="event-subtitle">
          Speeding
          {{
            event().context.VehicleSpeedCondition.currentSpeed | number: '1.0-0'
          }}
          mph
        </div>
      </div>

      <div class="event-timing">
        <div class="event-time label color-label">{{ timeAgo }}</div>
        <div class="event-read" *ngIf="!event().read"></div>
      </div>
    </div>
  `,
  styleUrls: ['./event-item.component.scss'],
  standalone: false,
})
export class VehicleSpeedEventItemComponent {
  event = input<EventHistoryItem>();
  momentService = inject(MomentService);
  timeAgo: string;

  constructor() {
    effect(() => {
      this.timeAgo = this.momentService.timeAgo(this.event().created);
    });
  }

  protected readonly corePlatformColors = corePlatformColors;
}
