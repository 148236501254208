import { computed, inject, Injectable } from '@angular/core';
import { AuthService, Customer, CustomerApiService, Employee } from '@fc-core';
import { EmployeeApiService } from '../../../employees/services/employee-api.service';
import { lastValueFrom } from 'rxjs';

import { Store } from '@fc-core/store-utils/store';

interface CustomerSelectState {
  customerList: Customer[];
  employeeList: Employee[];
  customerSearch: string;
  employeeSearch: string;
  employeeLoading: boolean;
  customerLoading: boolean;
  isEmployeeSelectorOpen: boolean;
  customerIndex: number;
  employeeIndex: number;
}

const initialCustomerSelectState: CustomerSelectState = {
  customerList: [],
  employeeList: [],
  customerSearch: '',
  employeeSearch: '',
  employeeLoading: false,
  customerLoading: false,
  isEmployeeSelectorOpen: false,
  customerIndex: 0,
  employeeIndex: 0,
};

@Injectable()
export class CustomerSelectStore extends Store<CustomerSelectState> {
  constructor() {
    super(initialCustomerSelectState);
  }
  customerApi = inject(CustomerApiService);
  employeeApiService = inject(EmployeeApiService);
  auth = inject(AuthService);

  // selectors
  customerSearch = computed(() => this._state().customerSearch);
  employeeSearch = computed(() => this._state().employeeSearch);
  isOpen = computed(() => this._state().isEmployeeSelectorOpen);
  employeeList = computed(() =>
    this._state().employeeList.filter((employee) =>
      `${employee.firstName} ${employee.lastName}`
        .toLowerCase()
        .includes(this.employeeSearch().toLowerCase()),
    ),
  );
  customerIndex = computed(() => this._state().customerIndex);
  employeeIndex = computed(() => this._state().employeeIndex);
  customerList = computed(() =>
    this._state().customerList.filter((customer) =>
      customer.name.toLowerCase().includes(this.customerSearch().toLowerCase()),
    ),
  );
  employeeLoading = computed(() => this._state().employeeLoading);
  customerLoading = computed(() => this._state().customerLoading);

  // actions
  setCustomerIndex(index: number) {
    this.update({ customerIndex: index });
  }

  setEmployeeIndex(index: number) {
    this.update({ employeeIndex: index });
  }

  setEmployeeSelectorOpen(isOpen: boolean) {
    this.update({ isEmployeeSelectorOpen: isOpen });
  }

  setCustomerSearch(search: string) {
    this.update({ customerSearch: search, customerIndex: 0 });
  }

  setEmployeeSearch(search: string) {
    this.update({ employeeSearch: search, employeeIndex: 0 });
  }

  async getEmployeeList(customerId: string) {
    this.update({
      employeeLoading: true,
      employeeList: [],
      isEmployeeSelectorOpen: true,
    });
    const resp = await lastValueFrom(
      this.employeeApiService.loadEmployees(customerId),
    );
    this.update({ employeeList: resp.list, employeeLoading: false });
  }

  async getCustomerList() {
    this.update({ customerLoading: true, customerList: [] });
    const resp = await lastValueFrom(this.customerApi.loadAll());
    this.update({ customerList: resp, customerLoading: false });
  }

  async switchCustomer(customer: Customer) {
    await lastValueFrom(this.auth.switchToCustomerDev(customer.id));
  }

  async switchEmployee(employee: Employee) {
    const customerId = this.customerList()[this.customerIndex()].id;
    await lastValueFrom(this.auth.switchToEmployee(employee.id, customerId));
  }
}
