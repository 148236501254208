import { Component, inject, OnInit } from '@angular/core';
import { NotificationsService } from '@fc-core/notifications/services/notifications.service';
import { MomentService } from '@fc-core';
import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { Store } from '@ngrx/store';
import { getLocations } from '@fc-locations/store';

@Component({
  selector: 'fc-events-tab',
  templateUrl: './events-tab.component.html',
  styleUrls: ['./events-tab.component.scss'],
  standalone: false,
})
export class EventsTabComponent implements OnInit {
  momentService = inject(MomentService);
  notificationService = inject(NotificationsService);
  store = inject(Store);
  groupedEvents$ = this.notificationService.eventsHistory$;

  ngOnInit() {
    this.notificationService.loadEventHistoryItems();
    this.store.dispatch(getLocations());
  }
}
