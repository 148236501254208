import { NavigationItem } from '@fc-core/navbar/navigation/navigation-item';
import { Permission } from '@fc-core';

export const defaultNavigation: NavigationItem[] = [
  {
    name: 'Dashboard',
    icon: 'icon-main_page',
    link: '/dashboard',
  },
  {
    name: 'Map',
    icon: 'icon-map',
    link: '/map',
  },
  {
    name: 'Locations',
    icon: 'icon-location',
    link: '/locations',
    featureKey: 'locations',
  },
  {
    name: 'CRM',
    icon: 'icon-crm',
    link: '/crm',
    featureKey: 'crm',
  },
  {
    name: 'Employees',
    icon: 'icon-group',
    link: '/users',
  },
  {
    name: 'Reports',
    icon: 'icon-reports',
    link: '/reports',
    featureKey: 'reports',
  },
  {
    name: 'Vehicles',
    icon: 'icon-vehicles',
    link: '/vehicles',
  },
  {
    name: 'Live share',
    icon: 'icon-live_share',
    link: '/shared',
  },
  {
    name: 'Chats',
    icon: 'icon-chats',
    link: '/chats',
    featureKey: 'chats',
  },
  {
    name: 'Settings',
    icon: 'icon-settings',
    open: false,
    inner: [
      {
        name: 'Company settings',
        icon: 'icon-vehicles_group',
        link: '/settings/main/company',
        permissionKey: 'fc_company_update',
      },
      {
        name: 'DVR settings',
        icon: 'icon-vehicles_group',
        link: '/settings/main/dvr-settings',
        permissionKey: 'fc_dvr_settings',
      },
      {
        name: 'Events',
        icon: 'icon-vehicles_group',
        link: '/events',
        featureKey: 'events',
      },
      {
        name: 'Apps management',
        icon: 'icon-vehicles_group',
        link: '/settings/main/app-management',
        featureKey: 'employee_time_clock_stats',
      },
    ],
  },
];

export function filterNavigation(
  navigation: NavigationItem[],
  permissions: Permission[],
  features: string[],
  newsToShow: boolean,
  messages: number,
): NavigationItem[] {
  return navigation
    .filter((navItem) => {
      if (navItem?.featureKey) {
        return features?.includes(navItem.featureKey);
      } else if (navItem?.permissionKey) {
        return permissions
          ?.map((permission) => permission.codename)
          ?.includes(navItem.permissionKey);
      } else if (navItem?.inner) {
        navItem.inner = filterNavigation(
          navItem.inner,
          permissions,
          features,
          newsToShow,
          messages,
        );
      }
      return true;
    })
    .map((navItem) =>
      setNavigationNotifications(navItem, newsToShow, messages),
    );
}

export function setNavigationNotifications(
  navigationItem: NavigationItem,
  newsToShow: boolean,
  messages: number,
): NavigationItem {
  if (navigationItem.name === 'News') navigationItem.notification = newsToShow;
  if (navigationItem.name === 'Chats') navigationItem.notification = !!messages;
  return navigationItem;
}
