import { Component, effect, inject, input, Input, Signal } from '@angular/core';
import { MomentService } from '@fc-core';
import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';
import { Store } from '@ngrx/store';
import { getLocationById } from '@fc-locations/store';
import { LocationInterface } from '@fc-locations/models/location-interface';
import { AppColorKeys, corePlatformColors } from '@fc-core/constants/colors';
import { AppIconsType, iconsList } from '@fc-shared/ui/icon/icons';

@Component({
  selector: 'fc-location-event-item',
  template: `
    <div
      class="event-item"
      matRipple
      [routerLink]="'locations/main/location-list/' + location()?.id"
    >
      <div class="image-container">
        <img [src]="event().context.vehicle.photo" alt="event-image" />
        <div class="status-icon" [style.background-color]="icon.background">
          <fc-icon
            [icon]="icon.icon"
            [color]="icon.color"
            [size]="16"
          ></fc-icon>
        </div>
      </div>

      <div class="event-info" *ngIf="event().context.vehicle">
        <div class="event-title h3">
          {{ event().context.vehicle.identifier }}
        </div>
        <div class="event-subtitle" *ngIf="location()">
          @if (event().context.LocationCondition.entered) {
            Entered
          }
          @if (event().context.LocationCondition.exited) {
            Exited
          }
          the
          <span class="active">{{ location().name }}</span>
          location
        </div>
      </div>

      <div class="event-timing">
        <div class="event-time label color-label">{{ timeAgo }}</div>
        <div class="event-read" *ngIf="!event().read"></div>
      </div>
    </div>
  `,
  styleUrls: ['./event-item.component.scss'],
  standalone: false,
})
export class LocationEventItemComponent {
  event = input<EventHistoryItem>();
  momentService = inject(MomentService);
  store = inject(Store);
  timeAgo: string;
  location: Signal<LocationInterface>;
  icon: {
    color: AppColorKeys;
    background: string;
    icon: AppIconsType;
  };

  constructor() {
    effect(() => {
      this.timeAgo = this.momentService.timeAgo(this.event().created);
      this.location = this.store.selectSignal(
        getLocationById(this.event().context.LocationCondition.locationId),
      );
      this.setIcon();
    });
  }

  private setIcon(): void {
    this.icon = this.event().context.LocationCondition.entered
      ? {
          color: 'primary600',
          background: corePlatformColors.primary100,
          icon: 'icon-exit_to_app',
        }
      : {
          color: 'secondary600',
          background: corePlatformColors.secondary100,
          icon: 'icon-logout',
        };
  }

  protected readonly corePlatformColors = corePlatformColors;
}
