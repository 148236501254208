import { Component, effect, inject, input } from '@angular/core';
import { MomentService } from '@fc-core';
import {
  EventHistoryItem,
  MovementType,
} from '@fc-core/notifications/models/event-history-item';
import { AppColorKeys, corePlatformColors } from '@fc-core/constants/colors';
import { AppIconsType, iconsList } from '@fc-shared/ui/icon/icons';

@Component({
  selector: 'fc-vehicle-state-event-item',
  template: `
    <div
      class="event-item"
      matRipple
      [routerLink]="
        'locations/main/location-list/' + event().context.vehicle.id
      "
    >
      <div class="image-container">
        <img [src]="event().context.vehicle.photo" alt="event-image" />
        <div class="status-icon" [style.background-color]="icon.background">
          <fc-icon
            [icon]="icon.icon"
            [color]="icon.color"
            [size]="16"
          ></fc-icon>
        </div>
      </div>

      <div class="event-info" *ngIf="event().context.vehicle">
        <div class="event-title h3">
          {{ event().context.vehicle.identifier }}
        </div>
        <div class="event-subtitle">
          {{ MovementType[event().context.MovementTypeCondition.current] }}
        </div>
      </div>

      <div class="event-timing">
        <div class="event-time label color-label">{{ timeAgo }}</div>
        <div class="event-read" *ngIf="!event().read"></div>
      </div>
    </div>
  `,
  styleUrls: ['./event-item.component.scss'],
  standalone: false,
})
export class VehicleStateEventItemComponent {
  event = input<EventHistoryItem>();
  momentService = inject(MomentService);
  timeAgo: string;
  icon: {
    color: AppColorKeys;
    background: string;
    icon: AppIconsType;
  };

  constructor() {
    effect(() => {
      this.timeAgo = this.momentService.timeAgo(this.event().created);
      this.icon = this.setIcon(
        this.event().context.MovementTypeCondition.current,
      );
    });
  }

  private setIcon(current: MovementType): {
    color: AppColorKeys;
    background: string;
    icon: AppIconsType;
  } {
    switch (current) {
      case MovementType.Stopped:
        return {
          color: 'secondary600',
          background: corePlatformColors.secondary100,
          icon: 'icon-status_stopped',
        };
      case MovementType.Idling:
        return {
          color: 'warning600',
          background: corePlatformColors.warning100,
          icon: 'icon-status_idling',
        };
      case MovementType.Towed:
        return {
          color: 'kepple600',
          background: corePlatformColors.kepple100,
          icon: 'icon-status_towed',
        };
      case MovementType.Traveling:
        return {
          color: 'success600',
          background: corePlatformColors.success100,
          icon: 'icon-status_traveling',
        };
    }
  }

  protected readonly MovementType = MovementType;
  protected readonly corePlatformColors = corePlatformColors;
}
