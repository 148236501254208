import { Component, inject, input } from '@angular/core';
import { NavigationItem } from '@fc-core/navbar/navigation/navigation-item';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';

@Component({
  selector: 'fc-navigation-item',
  template: `
    <div *ngIf="haveNotifications()" class="notification">
      <div class="notification-count"></div>
    </div>
    <fc-icon
      [size]="isOpen() ? 20 : 24"
      *ngIf="nav().icon"
      [icon]="nav().icon"
    ></fc-icon>
    <div class="nav-item-text">{{ nav().name }}</div>
    <fc-icon
      *ngIf="nav().inner && isOpen()"
      [class.collapsed]="nav().open"
      class="nav-item-arrow"
      icon="icon-arrow_drop_up"
    ></fc-icon>
  `,
  styleUrls: ['./navigation-item.component.scss'],
  imports: [MatIconModule, NgIf, IconComponent],
  host: {
    '[class.inner]': 'inner()',
    '[class.nav-open]': 'isOpen()',
    '[class.active]': 'itemActive',
  },
})
export class NavigationItemComponent {
  nav = input.required<NavigationItem>();
  haveNotifications = input<boolean>();
  inner = input<boolean>();
  isOpen = input<boolean>(true);
  router = inject(Router);

  get itemActive(): boolean {
    if (this.nav().inner) {
      return this.nav().inner.some((item) => item.link === this.router.url);
    } else {
      return this.router.url.includes(this.nav().link);
    }
  }
}
